/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $SaveProfessionalInfoRequestV2 = {
  properties: {
    profession: {
      type: 'Profession',
      isRequired: true,
    },
    registerNumber: {
      type: 'string',
      isNullable: true,
    },
    registerUF: {
      type: 'one-of',
      contains: [{
        type: 'UF',
      }],
      isNullable: true,
    },
    board: {
      type: 'string',
      isNullable: true,
    },
  },
} as const;
