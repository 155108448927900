/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ProfessionalListResponseData = {
  properties: {
    id: {
      type: 'string',
      isRequired: true,
    },
    cpf: {
      type: 'string',
      isRequired: true,
    },
    cnpj: {
      type: 'string',
      isRequired: true,
    },
    status: {
      type: 'string',
      isRequired: true,
    },
    name: {
      type: 'string',
      isRequired: true,
    },
    updatedAt: {
      type: 'string',
      isRequired: true,
    },
    register: {
      type: 'ProfessionalRegister',
    },
    syncStatus: {
      type: 'ProfessionalSyncStatus',
      isRequired: true,
    },
    specialties: {
      type: 'array',
      contains: {
        type: 'string',
      },
      isRequired: true,
    },
    providerId: {
      type: 'string',
      isRequired: true,
    },
    professionId: {
      type: 'Profession',
      isRequired: true,
    },
    professionDisplayName: {
      type: 'string',
      isRequired: true,
    },
    registerNumber: {
      type: 'string',
    },
    registerUF: {
      type: 'string',
    },
    businessPartner: {
      type: 'string',
    },
  },
} as const;
