/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum Profession {
  DOCTOR = 'DOCTOR',
  DENTIST = 'DENTIST',
  INFLUENCER = 'INFLUENCER',
  PSYCHOLOGIST = 'PSYCHOLOGIST',
  PSYCHOPEDAGOGUE = 'PSYCHOPEDAGOGUE',
  PHYSIOTHERAPIST = 'PHYSIOTHERAPIST',
  OCCUPATIONAL_THERAPIST = 'OCCUPATIONAL_THERAPIST',
  SPEECH_THERAPIST = 'SPEECH_THERAPIST',
  PHYSICAL_EDUCATOR = 'PHYSICAL_EDUCATOR',
  BIOMEDIC = 'BIOMEDIC',
  NUTRITIONIST = 'NUTRITIONIST',
  ART_THERAPIST = 'ART_THERAPIST',
  MUSIC_THERAPIST = 'MUSIC_THERAPIST',
  INSTRUMENTATOR = 'INSTRUMENTATOR',
  NURSE = 'NURSE',
  SOCIAL_WORKER = 'SOCIAL_WORKER',
  PSYCHOMOTOR_THERAPIST = 'PSYCHOMOTOR_THERAPIST',
  IMMOBILIZATION_TECHNICIAN = 'IMMOBILIZATION_TECHNICIAN',
  X_RAY_TECHNICIAN = 'X-RAY_TECHNICIAN',
  ACUPUNCTURE_THERAPIST = 'ACUPUNCTURE_THERAPIST',
}
