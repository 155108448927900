/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DoctorSavePersonalInfoRequest } from '../models/DoctorSavePersonalInfoRequest';
import type { DoctorSavePersonalInfoResponse } from '../models/DoctorSavePersonalInfoResponse';
import type { ListAvailableDoctorsSpecialtiesResponse } from '../models/ListAvailableDoctorsSpecialtiesResponse';
import type { SearchDoctorBasicInfoRequest } from '../models/SearchDoctorBasicInfoRequest';
import type { SearchDoctorBasicInfoResponse } from '../models/SearchDoctorBasicInfoResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class DoctorApi {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Search for doctor basic info inside national doctor databases
   * @param requestBody
   * @returns SearchDoctorBasicInfoResponse Returns the logged in user with its token or an error
   * @throws ApiError
   */
  public searchDoctorBasicInfo(
    requestBody: SearchDoctorBasicInfoRequest,
  ): CancelablePromise<SearchDoctorBasicInfoResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/user/me/doctor/searchDoctorBasicInfo',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Saves a doctor personal info creating a new person and professional if needed
   * @param requestBody
   * @returns DoctorSavePersonalInfoResponse Returns the logged in user with its token or an error
   * @throws ApiError
   */
  public savePersonalInfo(
    requestBody: DoctorSavePersonalInfoRequest,
  ): CancelablePromise<DoctorSavePersonalInfoResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/user/me/doctor/savePersonalInfo',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * List the available specialties from CFM
   * @returns ListAvailableDoctorsSpecialtiesResponse Returns success/APIError
   * @throws ApiError
   */
  public listDoctorsSpecialties(): CancelablePromise<ListAvailableDoctorsSpecialtiesResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/user/me/doctor/list-specialties',
    });
  }

}
