/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddressInfo } from '../models/AddressInfo';
import type { ProfessionalDigitalCertificate } from '../models/ProfessionalDigitalCertificate';
import type { SavePersonBiometricsProviderResponse } from '../models/SavePersonBiometricsProviderResponse';
import type { SavePersonBiometricsRequest } from '../models/SavePersonBiometricsRequest';
import type { SavePersonInfoRequest } from '../models/SavePersonInfoRequest';
import type { SavePersonInfoResponse } from '../models/SavePersonInfoResponse';
import type { SavePersonManualBiometricsRequest } from '../models/SavePersonManualBiometricsRequest';
import type { SearchPersonInfoRequest } from '../models/SearchPersonInfoRequest';
import type { SearchPersonInfoResponse } from '../models/SearchPersonInfoResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PersonService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Save a person address
   * @param requestBody
   * @returns any The request has succeeded.
   * @throws ApiError
   */
  public savePersonAddress(
    requestBody: {
      address: AddressInfo;
    },
  ): CancelablePromise<{
    success: boolean;
    metadata?: Record<string, any>;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/user/me/person/save-address',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `The server could not understand the request due to invalid syntax.`,
        500: `Server error`,
      },
    });
  }

  /**
   * Sets person's digital certificates
   * @param requestBody
   * @returns any The request has succeeded.
   * @throws ApiError
   */
  public setPersonDigitalCertificate(
    requestBody: ProfessionalDigitalCertificate,
  ): CancelablePromise<{
    success: boolean;
    metadata?: Record<string, any>;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/user/me/person/set-person-digital-certificate',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `The server could not understand the request due to invalid syntax.`,
        401: `Access is unauthorized.`,
        403: `Access is forbidden`,
        404: `The server cannot find the requested resource.`,
        500: `Server error`,
      },
    });
  }

  /**
   * Retrive person info by cpf
   * @param requestBody
   * @returns any The request has succeeded.
   * @throws ApiError
   */
  public searchPersonInfoByCpf(
    requestBody: SearchPersonInfoRequest,
  ): CancelablePromise<{
    success: boolean;
    data?: SearchPersonInfoResponse;
    metadata?: Record<string, any>;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/user/me/person/search-person-info-by-cpf',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `The server could not understand the request due to invalid syntax.`,
        401: `Access is unauthorized.`,
        403: `Access is forbidden`,
        404: `The server cannot find the requested resource.`,
        500: `Server error`,
      },
    });
  }

  /**
   * Save a new person Entity
   * @param requestBody
   * @returns any The request has succeeded.
   * @throws ApiError
   */
  public savePersonInfo(
    requestBody: SavePersonInfoRequest,
  ): CancelablePromise<{
    success: boolean;
    data?: SavePersonInfoResponse;
    metadata?: Record<string, any>;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/user/me/person/save-person-info',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `The server could not understand the request due to invalid syntax.`,
        401: `Access is unauthorized.`,
        403: `Access is forbidden`,
        500: `Server error`,
      },
    });
  }

  /**
   * Save a person's biometrics
   * @param requestBody
   * @returns any The request has succeeded.
   * @throws ApiError
   */
  public savePersonBiometrics(
    requestBody: SavePersonBiometricsRequest,
  ): CancelablePromise<{
    success: boolean;
    metadata?: Record<string, any>;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/user/me/person/save-person-biometrics',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `The server could not understand the request due to invalid syntax.`,
        401: `Access is unauthorized.`,
        403: `Access is forbidden`,
        404: `The server cannot find the requested resource.`,
        500: `Server error`,
      },
    });
  }

  /**
   * Save a person's manual biometrics
   * @param requestBody
   * @returns any The request has succeeded.
   * @throws ApiError
   */
  public savePersonManualBiometrics(
    requestBody: SavePersonManualBiometricsRequest,
  ): CancelablePromise<{
    success: boolean;
    metadata?: Record<string, any>;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/user/me/person/save-person-manual-biometrics',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `The server could not understand the request due to invalid syntax.`,
        401: `Access is unauthorized.`,
        403: `Access is forbidden`,
        404: `The server cannot find the requested resource.`,
        500: `Server error`,
      },
    });
  }

  /**
   * Save a person's manual biometrics
   * @returns any The request has succeeded.
   * @throws ApiError
   */
  public savePersonBiometricsProvider(): CancelablePromise<{
    success: boolean;
    data?: SavePersonBiometricsProviderResponse;
    metadata?: Record<string, any>;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/user/me/person/save-person-biometrics-provider',
      errors: {
        400: `The server could not understand the request due to invalid syntax.`,
        401: `Access is unauthorized.`,
        403: `Access is forbidden`,
        404: `The server cannot find the requested resource.`,
        500: `Server error`,
      },
    });
  }

}
