/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { FetchHttpRequest } from './core/FetchHttpRequest';

import { AdminService } from './services/AdminService';
import { CompanyService } from './services/CompanyService';
import { DoctorService } from './services/DoctorService';
import { MarketplaceService } from './services/MarketplaceService';
import { PersonService } from './services/PersonService';
import { ProfessionService } from './services/ProfessionService';
import { ProfessionalService } from './services/ProfessionalService';
import { SpecialtyService } from './services/SpecialtyService';
import { SupService } from './services/SupService';
import { SysService } from './services/SysService';
import { UserService } from './services/UserService';
import { UserActionTokenService } from './services/UserActionTokenService';
import { UserTermService } from './services/UserTermService';
import { WorkflowService } from './services/WorkflowService';

type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;

export class DoctorOnboardingAPI {

  public readonly admin: AdminService;
  public readonly company: CompanyService;
  public readonly doctor: DoctorService;
  public readonly marketplace: MarketplaceService;
  public readonly person: PersonService;
  public readonly profession: ProfessionService;
  public readonly professional: ProfessionalService;
  public readonly specialty: SpecialtyService;
  public readonly sup: SupService;
  public readonly sys: SysService;
  public readonly user: UserService;
  public readonly userActionToken: UserActionTokenService;
  public readonly userTerm: UserTermService;
  public readonly workflow: WorkflowService;

  public readonly request: BaseHttpRequest;

  constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = FetchHttpRequest) {
    this.request = new HttpRequest({
      BASE: config?.BASE ?? 'http://localhost:3000/api/v1',
      VERSION: config?.VERSION ?? '0.0.1',
      WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
      CREDENTIALS: config?.CREDENTIALS ?? 'include',
      TOKEN: config?.TOKEN,
      USERNAME: config?.USERNAME,
      PASSWORD: config?.PASSWORD,
      HEADERS: config?.HEADERS,
      ENCODE_PATH: config?.ENCODE_PATH,
    });

    this.admin = new AdminService(this.request);
    this.company = new CompanyService(this.request);
    this.doctor = new DoctorService(this.request);
    this.marketplace = new MarketplaceService(this.request);
    this.person = new PersonService(this.request);
    this.profession = new ProfessionService(this.request);
    this.professional = new ProfessionalService(this.request);
    this.specialty = new SpecialtyService(this.request);
    this.sup = new SupService(this.request);
    this.sys = new SysService(this.request);
    this.user = new UserService(this.request);
    this.userActionToken = new UserActionTokenService(this.request);
    this.userTerm = new UserTermService(this.request);
    this.workflow = new WorkflowService(this.request);
  }
}

