/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $Professional = {
  properties: {
    uuid: {
      type: 'string',
      isRequired: true,
    },
    status: {
      type: 'ProfessionalStatus',
      isRequired: true,
    },
    professionId: {
      type: 'Profession',
      isRequired: true,
    },
    profession: {
      type: 'ProfessionInfoWithBoards',
      isRequired: true,
    },
    registerNumber: {
      type: 'string',
    },
    registerUF: {
      type: 'string',
    },
    registerStatus: {
      type: 'ProfessionalRegisterStatus',
    },
    registerAdditionalInfo: {
      type: 'dictionary',
      contains: {
        properties: {
        },
      },
    },
    board: {
      type: 'Board',
    },
    otherRegisters: {
      type: 'array',
      contains: {
        type: 'OtherProfessionalRegister',
      },
      isRequired: true,
    },
  },
} as const;
