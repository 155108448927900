/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EmailOtpVerifyRequest } from '../models/EmailOtpVerifyRequest';
import type { FacialRecognitionRequest } from '../models/FacialRecognitionRequest';
import type { FeatureFlags } from '../models/FeatureFlags';
import type { GetUserWorkflowStepsRequest } from '../models/GetUserWorkflowStepsRequest';
import type { GetUserWorkflowStepsResponse } from '../models/GetUserWorkflowStepsResponse';
import type { ListBanksResponse } from '../models/ListBanksResponse';
import type { ManualFacialRecognitionRequest } from '../models/ManualFacialRecognitionRequest';
import type { Response } from '../models/Response';
import type { SendEmailOtpRequest } from '../models/SendEmailOtpRequest';
import type { SendProfessionalDocumentsRequest } from '../models/SendProfessionalDocumentsRequest';
import type { SendProfessionalDocumentsResponse } from '../models/SendProfessionalDocumentsResponse';
import type { SendSmsOtpRequest } from '../models/SendSmsOtpRequest';
import type { SetDigitalCertificateRequest } from '../models/SetDigitalCertificateRequest';
import type { SmsOtpVerifyRequest } from '../models/SmsOtpVerifyRequest';
import type { UserAuthResponseData } from '../models/UserAuthResponseData';
import type { UserComplianceRequest } from '../models/UserComplianceRequest';
import type { UserComplianceResponse } from '../models/UserComplianceResponse';
import type { UserMeInfo } from '../models/UserMeInfo';
import type { UserResponse } from '../models/UserResponse';
import type { Workflow } from '../models/Workflow';
import type { WorkflowStepId } from '../models/WorkflowStepId';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class UserApi {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get the logged-in user
   * @returns UserResponse The logged-in user
   * @throws ApiError
   */
  public meUser(): CancelablePromise<UserResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/user/me',
    });
  }

  /**
   * Answer to the compliance questions
   * @param requestBody
   * @returns UserComplianceResponse Returns if the request was sucessful
   * @throws ApiError
   */
  public complianceQuestions(
    requestBody: UserComplianceRequest,
  ): CancelablePromise<UserComplianceResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/user/me/complianceQuestions',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Returns a list of all available banks
   * @returns ListBanksResponse Returns the logged in user with its token or an error
   * @throws ApiError
   */
  public listBanks(): CancelablePromise<ListBanksResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/user/me/listBanks',
    });
  }

  /**
   * Notify doctor onboarding api that a user completed its facial recognition
   * @param requestBody
   * @returns Response Returns the logged in user with its token or an error
   * @throws ApiError
   */
  public facialRecognitionCompleted(
    requestBody: FacialRecognitionRequest,
  ): CancelablePromise<Response> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/user/me/facial-recognition-completed',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Notify doctor onboarding api that a support team completed manual facial recognition
   * @param requestBody
   * @returns Response Returns the logged in user with its token or an error
   * @throws ApiError
   */
  public manualFacialRecognitionCompleted(
    requestBody: ManualFacialRecognitionRequest,
  ): CancelablePromise<Response> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/user/me/sup/manual-facial-recognition-completed',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * From verification code by email or sms complete the login method
   * @param requestBody
   * @returns any The request has succeeded.
   * @throws ApiError
   */
  public confirmMethod(
    requestBody: (SmsOtpVerifyRequest | EmailOtpVerifyRequest),
  ): CancelablePromise<{
    success: boolean;
    metadata?: Record<string, any>;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/user/me/confirm',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `The server could not understand the request due to invalid syntax.`,
        401: `Access is unauthorized.`,
        403: `Access is forbidden`,
        500: `Server error`,
      },
    });
  }

  /**
   * Send verification code by email or sms to user to complete the login method
   * @param requestBody
   * @returns any The request has succeeded.
   * @throws ApiError
   */
  public sendConfirm(
    requestBody: (SendSmsOtpRequest | SendEmailOtpRequest),
  ): CancelablePromise<{
    success: boolean;
    metadata?: Record<string, any>;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/user/me/send-confirm',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `The server could not understand the request due to invalid syntax.`,
        500: `Server error`,
      },
    });
  }

  /**
   * Verify user auth and data
   * @returns any The request has succeeded.
   * @throws ApiError
   */
  public auth(): CancelablePromise<{
    success: boolean;
    data?: UserAuthResponseData;
    metadata?: Record<string, any>;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/user/me/auth',
      errors: {
        401: `Access is unauthorized.`,
        500: `Server error`,
      },
    });
  }

  /**
   * Get user info
   * @returns any The request has succeeded.
   * @throws ApiError
   */
  public meInfo(): CancelablePromise<{
    success: boolean;
    data?: UserMeInfo;
    metadata?: Record<string, any>;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/user/me/info',
      errors: {
        401: `Access is unauthorized.`,
        500: `Server error`,
      },
    });
  }

  /**
   * Get user info
   * @returns any The request has succeeded.
   * @throws ApiError
   */
  public meInfoV2(): CancelablePromise<{
    success: boolean;
    data?: UserMeInfo;
    metadata?: Record<string, any>;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/user/me/info-v2',
      errors: {
        401: `Access is unauthorized.`,
        500: `Server error`,
      },
    });
  }

  /**
   * Receive the person's professional document for validation
   * @param requestBody
   * @returns SendProfessionalDocumentsResponse Returns success/APIError
   * @throws ApiError
   */
  public setProfessionalDocument(
    requestBody: SendProfessionalDocumentsRequest,
  ): CancelablePromise<SendProfessionalDocumentsResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/user/me/professional/set-documents',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Receive the person's digital certifications choice
   * @param requestBody
   * @returns Response Returns success/APIError
   * @throws ApiError
   */
  public setDigitalCertificate(
    requestBody: SetDigitalCertificateRequest,
  ): CancelablePromise<Response> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/user/me/professional/set-digital-certificate',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Get the steps for a given user workflow
   * @param requestBody
   * @returns GetUserWorkflowStepsResponse Returns success/APIError
   * @throws ApiError
   */
  public getUserWorkflow(
    requestBody: GetUserWorkflowStepsRequest,
  ): CancelablePromise<GetUserWorkflowStepsResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/user/me/workflow/getSteps',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Get all the steps for a given workflow
   * @param requestBody
   * @returns any Returns success/APIError
   * @throws ApiError
   */
  public getUserWorkflowNextStep(
    requestBody: {
      requestedStepId: WorkflowStepId;
      workflowId: Workflow;
    },
  ): CancelablePromise<(Response & {
    data: {
      workflowStepId?: WorkflowStepId;
      toStatus?: boolean;
    };
  })> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/user/me/workflow/getNextStep',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * From verification code by email or sms login user
   * @param requestBody
   * @returns any The request has succeeded.
   * @throws ApiError
   */
  public login(
    requestBody: (SmsOtpVerifyRequest | EmailOtpVerifyRequest),
  ): CancelablePromise<{
    success: boolean;
    metadata?: Record<string, any>;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/user/login',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `The server could not understand the request due to invalid syntax.`,
        401: `Access is unauthorized.`,
        403: `Access is forbidden`,
        500: `Server error`,
      },
    });
  }

  /**
   * Send login verification code by email or sms to user
   * @param requestBody
   * @returns any The request has succeeded.
   * @throws ApiError
   */
  public sendLogin(
    requestBody: (SendSmsOtpRequest | SendEmailOtpRequest),
  ): CancelablePromise<{
    success: boolean;
    metadata?: Record<string, any>;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/user/send-login',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `The server could not understand the request due to invalid syntax.`,
        500: `Server error`,
      },
    });
  }

  /**
   * From verification code by email or sms signup user
   * @param requestBody
   * @returns any The request has succeeded.
   * @throws ApiError
   */
  public signup(
    requestBody: (SmsOtpVerifyRequest | EmailOtpVerifyRequest),
  ): CancelablePromise<{
    success: boolean;
    metadata?: Record<string, any>;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/user/signup',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `The server could not understand the request due to invalid syntax.`,
        401: `Access is unauthorized.`,
        403: `Access is forbidden`,
        500: `Server error`,
      },
    });
  }

  /**
   * Send signup verification code by email or sms to user
   * @param requestBody
   * @returns any The request has succeeded.
   * @throws ApiError
   */
  public sendSignup(
    requestBody: (SendSmsOtpRequest | SendEmailOtpRequest),
  ): CancelablePromise<{
    success: boolean;
    metadata?: Record<string, any>;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/user/send-signup',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `The server could not understand the request due to invalid syntax.`,
        500: `Server error`,
      },
    });
  }

  /**
   * Get user feature flags
   * @returns any The request has succeeded.
   * @throws ApiError
   */
  public listFeatures(): CancelablePromise<{
    success: boolean;
    data?: FeatureFlags;
    metadata?: Record<string, any>;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/user/me/feature/list',
      errors: {
        401: `Access is unauthorized.`,
        500: `Server error`,
      },
    });
  }

  /**
   * Complete basic_info step for user
   * @param requestBody
   * @returns any The request has succeeded.
   * @throws ApiError
   */
  public completeBasicInfo(
    requestBody: {
      userId: string;
    },
  ): CancelablePromise<{
    success: boolean;
    metadata?: Record<string, any>;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/user/me/complete-basic-info',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Access is unauthorized.`,
        500: `Server error`,
      },
    });
  }

}
