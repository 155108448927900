/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ProfessionalInfoResponseData = {
  properties: {
    professional: {
      type: 'all-of',
      contains: [{
        type: 'Professional',
      }, {
        properties: {
          id: {
            type: 'string',
            isRequired: true,
          },
        },
      }],
    },
    person: {
      type: 'Person',
    },
    company: {
      type: 'Company',
    },
    professional_documents: {
      type: 'array',
      contains: {
        type: 'ProfessionalInfoDocument',
      },
    },
    company_documents: {
      type: 'array',
      contains: {
        type: 'ProfessionalInfoCompanyDocument',
      },
    },
    backgroundCheck: {
      type: 'GetBackgroundCheckResponseData',
    },
    validationResults: {
      type: 'array',
      contains: {
        type: 'AdminValidationResult',
      },
    },
    bussiness_partner: {
      type: 'BussinessPartner',
    },
    provider_id: {
      type: 'string',
    },
  },
} as const;
